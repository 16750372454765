<template>
    <div>
        <v-overlay :value="$apollo.loading">
            <v-progress-circular indeterminate size="64" />
        </v-overlay>
        <v-dialog v-model="inviteDialog" persistent max-width="290">
            <v-card>
                <v-card-text class="pt-4">
                    You have been invited to join
                    <b>{{ invite ? invite.organizationName : 'N/A' }}</b> as an
                    organization
                    {{ inviteAsAdmin ? 'administrator' : 'member' }}. Would you
                    like to proceed to your account details to accept the
                    invitation?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="acceptInvite">
                        Accept
                    </v-btn>
                    <v-btn color="primary" text @click="inviteDialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
    name: 'CheckOrgInvite',
    data() {
        return {
            inviteDialog: false,
            loading: false,
            snackbar: false,
            snackbarMessage: ''
        }
    },
    apollo: {
        invite: {
            skip() {
                return (
                    !this.$auth.isAuthenticated ||
                    this.$auth.userInOrganization()
                )
            },
            query: gql`
                query {
                    resolveAccountOrganizationInvite {
                        orgId
                        organizationName
                        pendingInvites {
                            requestedEmail
                            admin
                        }
                    }
                }
            `,
            update(result) {
                return result.resolveAccountOrganizationInvite
            }
        }
    },
    computed: {
        inviteAsAdmin() {
            if (!this.invite) return false
            return this.invite.pendingInvites.find(
                (e) =>
                    e.requestedEmail.toLowerCase() ===
                    this.$auth.user.email.toLowerCase()
            ).admin
        }
    },
    watch: {
        invite(value) {
            if (value) {
                this.inviteDialog = true
            }
        }
    },
    methods: {
        acceptInvite() {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation AcceptOrganizationInvite($orgId: String!) {
                            acceptOrganizationInvite(orgId: $orgId) {
                                complete
                                error
                                success
                                uid
                            }
                        }
                    `,
                    variables: {
                        orgId: this.invite.orgId
                    }
                })
                .then((data) => {
                    if (data.data.acceptOrganizationInvite.success === true) {
                        this.snackbar = true
                        this.snackbarMessage = `You are now accepted into the organization`
                        window.location.reload()
                    } else {
                        this.snackbar = true
                        this.snackbarMessage = `Error accepting invite`
                    }
                })
        }
    }
}
</script>
